
/*
import Dashboard from './pages/Dashboard.vue'
import Users from "./pages/Users.vue"
import Groups from "./pages/Groups.vue"
import Displays from "./pages/Display.vue"
import Maps from "./pages/maps/_Maps.vue"
import Devices from "./pages/devices/Devices.vue"
import Variables from "./pages/variables/Variables.vue"
import Schedules from "./pages/schedules/Schedules.vue"
import ColorConfig from './pages/ColorConfig.vue'
*/

//import History from "./pages/History.vue"


/*
export const routes = [
    { path: '/dashboard', name: 'dashboard', component: Dashboard, title: 'Dashboard', menu: true},
    { path: '/users', name: 'users', component: Users, title: 'Users', menu: true},
    { path: '/groups', name: 'groups', component: Groups, title: 'groups', menu: true},
    { path: '/displays', name: 'displays', component: Displays, title: 'displays', menu: true},
    { path: '/colorConfig', name: 'colorConfig', component: ColorConfig, title: 'colorConfig', menu: true},
    { path: '/maps', name: 'maps', component: Maps, title: 'maps', menu: true},
    { path: '/devices', name: 'devices', component: Devices, title: 'devices', menu: true},
    { path: '/variables',  name: 'variables', component: Variables, title: 'variables', menu: true},
    { path: '/schedules',  name: 'schedules', component: Schedules, title: 'schedules', menu: true},
    { path: '/', name: 'home', component: Devices, title: 'home', menu: true},
];



*/

export const routes = [

]