import api from "../../api";
import axios from "axios";

let connection_error_time = 0;

function ellapsedTime(){
    const currentTime = new Date().getTime()
    const diff = (currentTime - connection_error_time ) / 1000
    return diff
}

function canMakeRequest(failedConnectionWithAxios){
    if(!failedConnectionWithAxios) return true
    const diff = ellapsedTime()
    return diff > 30
}

function InterceptRequest(context){

    api.interceptors.request.use(request => {
        const cancelToken = axios.CancelToken.source();
        request.cancelToken = cancelToken.token;

        
        if(canMakeRequest(context.failedConnectionWithAxios)){
            return interceptRequestSuccess(request, context)
        }else {
            cancelToken.cancel("Connection error")
        }
    }, error => {
        console.log(error)
        return Promise.reject(error)
    })
}

function interceptRequestSuccess(request, context){
    var methods = ['post', 'put', 'delete']
    var urlsToIgnore = ["/api/Data/", "/api/Message", "/api/Displays", "/api/ColorConfigs", "/api/DashboardsDisplays", "/api/GroupsDisplays", '/api/AlarmHistory/comment/']
    var ignore =  urlsToIgnore.map(i => request.url.includes(i)).filter(a => a).length > 0
    if(methods.includes(request.method) && !ignore){
        context.loading = true
        console.log("LOADING...")
        setTimeout(() => {
            if(context.loading){
                context.loading = false
                context.alertMessage = 'timeout'
                context.alertColor = "error"
                context.showAlert();
            }
        }, 10000)
    }
    return request
}

function InterceptResponse(context){
    api.interceptors.response.use(reponse => {
        return interceptResponseSuccess(reponse, context)
    }, error => {
        if(axios.isCancel(error)) {
            Promise.reject(error)
        }
        else if(error.code == "ERR_NETWORK") return interceptResponseConnectionRejected(error, context) 
        else return interceptResponseError(error, context)
    })
}

function getTranslationKey(message){
    if (message.length <= 0) return ''
    /*A mensagem vem como: "resposta da requisição"
    * deve ser transformado para "respostaDaRequisição"
    */
    const words = message.split(' '); //separa as palavras
    // deixa a primeira letra de cada palavra maiuscula
    const capitalizedWords = words.map((word, index) => {
        if (index == 0) return word
        const firstLetter = word.charAt(0).toUpperCase();
        const restOfWord = word.slice(1);
        return firstLetter + restOfWord;
    });
    const camelCaseString = capitalizedWords.join('').replace(/\s/g, '');
    return camelCaseString
}

function interceptResponseSuccess(reponse, context){
    context.failedConnectionWithAxios = false
    const responseMessage = reponse.data.message
    if(responseMessage != undefined){
        console.log(reponse)
        if(responseMessage.length > 0){
            let translationKey = getTranslationKey(responseMessage)
            context.alertMessage = translationKey
            context.alertColor = "success"
            if(translationKey.length > 0) context.showAlert()
            setTimeout(() => {
                context.loading = false
            }, 1000)
        }
    }
    return reponse
}

function interceptResponseError(error, context){
    const message = error.response.data.message == undefined ? error.response.data : error.response.data.message
    context.loading = false
    context.alertMessage = getTranslationKey(message)
    context.errorOrigin = error.config.url.split('/')[2].slice(0, -1)
    context.alertColor = "error"
    if (context.alertMessage.length > 0) context.showAlert()
    setTimeout(() => {
        context.loading = false
    }, 5000);
    return Promise.reject(error)
}

function interceptResponseConnectionRejected(error, context){
    if(!context.failedConnectionWithAxios || ellapsedTime() > 30 ){
        connection_error_time = new Date().getTime()
    }
    context.failedConnectionWithAxios = true
    return Promise.reject(error)
}

export default {InterceptRequest, InterceptResponse}