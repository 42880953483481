<template>
    <div class="page">
        <transition-group tag="div" class="div-slider" :name="animationName">
            <section class="mobileContainerHome" v-if="currentPage == 0" :key="0">
                <div class="w-100 d-flex itemContainer ">
                    <div class="itemName" @click="goToDashboardList">
                        <b-icon-layout-wtf font-scale="3" />
                        <h1 class="ml-2">{{$i18n.t('dashboards')}}</h1>
                    </div>
                    <b-icon-arrow-right-circle-fill/>
                </div>
                <div class="w-100 d-flex itemContainer">
                    <div class="itemName" @click="redirectPage('/map')">
                        <b-icon-map font-scale="3" />
                        <h1 class="ml-2">{{$i18n.t('map')}}</h1>
                    </div>
                    <b-icon-arrow-right-circle-fill/>
                </div>
                <div class="w-100 d-flex itemContainer">
                    <div class="itemName" @click="goToBlueprintList">
                        <b-icon-card-image font-scale="3" />
                        <h1 class="ml-2">{{$i18n.t('blueprint')}}</h1>
                    </div>
                    <b-icon-arrow-right-circle-fill/>
                </div>
            </section>
            
            <section class="listContainer" v-else-if="currentPage == 1" :key="1">
                <ul class="w-100 h-100" v-if="dashboards.length >= 1">
                    <li class="listItem" v-for="dashboard in dashboards" :key="dashboard.id" @click="redirectPage(`/dashboard/${dashboard.id}`)">
                        <h3>{{dashboard.name}}</h3>
                        <b-icon-arrow-right-circle-fill/>
                    </li>
                </ul>
                <div v-else class="w-100 h-100 pt-5 d-flex justify-content-center align-items-center flex-column">
                    <b-icon-x-octagon-fill font-scale="4"/>
                    <h1 class="mt-2">{{$i18n.t('noDashboards')}}</h1>
                </div>
            </section>

            <section class="listContainer" v-else-if="currentPage == 2" :key="2">
                <ul class="w-100 h-100">
                    <li class="listItem" v-for="blueprint of blueprints" :key="blueprint.id" @click="redirectPage(`/blueprint/map/${blueprint.id}`)">
                        <div class="info">
                            <img :src="`data:image/png;base64,${blueprint.image}`"/>
                            <h3>{{blueprint.name}}</h3>
                        </div>
                        <b-icon-arrow-right-circle-fill/>

                    </li>
                </ul>
            </section>
        </transition-group>
    </div>
</template>

<script>
export default {
    data(){
        return{
            currentPage: 0,
            isGoingBack: false,
            dashboards: [],
            blueprints: [],
            redirect: false,
        }
    },
    computed:{
        animationName(){
            return this.isGoingBack ? 'slideback' : 'slide'
        }
    },
    methods:{
        goToDashboardList(){
            this.currentPage = 1
        },
        goToBlueprintList(){
            this.currentPage = 2
        },
        redirectPage(route){
            this.redirect = true
            console.log(route)
            this.$router.push(route)
        }
    },
    watch:{
        currentPage(val, old){
            this.isGoingBack = val < old
        },
    },
    mounted(){
        this.service = new this.$dashboardService()
        this.blueprintService = new this.$blueprintService()
        const userId = this.$session.get("logged_id")
        this.service.listByUserId(userId).then(dashboards => {
            this.dashboards = dashboards.filter(i => i.enable)
        })

        this.blueprintService.listByUserId(userId).then(blueprints => {
            this.blueprints = blueprints.filter(i => i.enable)
        })

        this.redirect = false
    },
    beforeRouteLeave(to, from, next) {
        if (this.currentPage == 1 && !this.redirect) {
            this.currentPage = 0

            next(false);
        } else {
            next();
        }
    },
    beforeDestroy(){
        this.redirect = false
    },

}
</script>
<style>
    #app{
        overflow: hidden;
    }
</style>
<style lang="scss" scoped>
.page{
    background-color: rgba(var(--tmHubTheme-darken-primary-color, 1, 6, 16), 0.811);
}
.mobileContainerHome{
    display: flex;
    flex-direction: column;
    height: 93vh;
    overflow-y: hidden;
    .itemContainer{
        flex: 1;
        display: flex;
        align-items: center;
        padding: 2rem;
        border-bottom: 2px solid rgb(var(--tmHubTheme-lighten-primary-color, 19, 21, 59));
        backdrop-filter: blur(16px) saturate(180%);
        -webkit-backdrop-filter: blur(16px) saturate(180%);
        // background-color: rgba(1, 6, 16, 0.811);
        transition: background-color 0.2s ease;
        .itemName{
            display: flex;
            align-items: center;
            width: 100%;
            flex-direction: column;
            gap: 1rem;

        }
        &:nth-child(1){
            padding-top: 1.5rem;
            border-top: 2px solid rgb(var(--tmHubTheme-lighten-primary-color, 19, 21, 59));
        }
        svg{
            color: white;
            font-size: 2rem;
        }
        &:active{
            background: rgba(var(--tmHubTheme-lighten-primary-color, 19, 21, 59), 0.8);
            transition: background-color 0.2s ease;
        }
    }
}
.listContainer{
    min-height: 93vh;
    width: 100vw;
    .listItem{
        padding: 2rem;
        height: 10rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid rgb(var(--tmHubTheme-lighten-primary-color, 19, 21, 59));
        backdrop-filter: blur(16px) saturate(180%);
        -webkit-backdrop-filter: blur(16px) saturate(180%);
        transition: background-color 0.2s ease;
        &:nth-child(1){
            padding-top: 1.5rem;
            border-top: 2px solid rgb(var(--tmHubTheme-lighten-primary-color, 19, 21, 59));
        }
        svg{
            color: white;
            font-size: 2rem;
        }
        &:active{
            background: rgba(var(--tmHubTheme-lighten-primary-color, 19, 21, 59), 0.8);
            transition: background-color 0.2s ease;
        }

        .info{
            display: flex;
            align-items: center;
            gap: 0.5rem;
            img{
                height: 4rem;
                width: 6rem;
            }
        }

    }
}

.slide-leave-active,
    .slide-enter-active {
    transition: 1s;
    }
    .slide-enter {
    transform: translate(200%, 0);
    }
    .slide-leave-to {
    transform: translate(-200%, 0);
    }

    .slideback-leave-active,
    .slideback-enter-active {
    transition: 1s;
    }
    .slideback-enter {
    transform: translate(-200%, 0);
    }
    .slideback-leave-to {
    transform: translate(200%, 0);
    }
</style>